import React, { useEffect, Fragment } from 'react';

import PostopInstCataract from './instructions/PostopInstCataract';
import PreopInstCataract from './instructions/PreopInstCataract';
import BedsheetRead from './bedsheet/BedsheetRead'
import IhcConsent from './consents/IhcConsent'
import Vf8Questionnaire from './questionnaire/Vf8Questionnaire'
import ConsentRead from './consents/ConsentRead'
import BackArrow from '../partials/BackArrow'
import PrintPage from '../partials/PrintPage'


export default function Paperwork (props) { 

  let pageBreak =
    <div>
      <div
        style ={{
          pageBreakBefore: 'always'}}
          >
      </div>
      <div style={{
          height: '150px',
          borderBottom: '1px dashed gray',
          margin: '0 0 40px 0',
        }}
        className = 'dontPrint'
        >
      </div>
    </div>

  let pStyle = {
      margin: '15px 0 0 0'
    }



  useEffect(() => {
      window.scrollTo(0, 0)
    }, [])


// lyndontyler.com===========Change the following:  index.js G-tag to nc, "/" path in App.js to Login, add allergies to patient info =======================================


return(
  <div>
    {props.lensOption === 'Cataract vision'
      ?
      <div>
        <BackArrow
          linkTo='/patientinfo'
          />
        <p style={{
            margin: '15px auto 0 auto',
            color: 'red',
            maxWidth: '800px',
          }}>
          REFRESHING THE SCREEN ERASES THE PATIENT INFORMATION YOU ENTERED PREVIOUSLY. PLEASE CLICK THE BACK ARROW TO RE-ENTER THE PATIENT'S INFORMATION.  DO NOT REFRESH YOUR BROWSER NEXT TIME.
        </p>
      </div>
    :

      <div>
        <BackArrow
          linkTo='/nightsimulator'
          />
        <PrintPage/>

        
          {
            (props.bedsheetData && props.bedsheetData[0].data.length > 0)
            ? 
              <>
                <BedsheetRead
                  power={props.power}
                  cylinder={props.cylinder}
                  axis={props.axis}
                  add={props.add}
                  lensOption={props.lensOption}
                  lensModel={props.lensModel}
                  sulcusLens={props.sulcusLens}
                  lensHospitalFee={props.lensHospitalFee}
                  lensPhysicianFee={props.lensPhysicianFee}
                  lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                  lensCorrectsNearVision={props.lensCorrectsNearVision}
                  flacs={props.flacs}
                  cri={props.cri}
                  lensTargetNear={props.lensTargetNear}
                  biometer={props.biometer}
                  biometerAstig={props.biometerAstig}
                  biometerAxis={props.biometerAxis}
                  chordMuX={props.chordMuX}
                  chordMuY={props.chordMuY}
                  chordMuTotal={props.chordMuTotal}
                  topographer={props.topographer}
                  topographerAstig={props.topographerAstig}
                  topographerAxis={props.topographerAxis}
                  topographerHoa={props.topographerHoa}
                  topographerSphAb={props.topographerSphAb}
                  equipmentPreference={props.equipmentPreference}
                  lensKAstigMin={props.lensKAstigMin}
                  bcva={props.bcva}
                  ns={props.ns}
                  contraindications={props.contraindications}
                  cataractAddOn={props.cataractAddOn}
                  setCataractAddOn={props.setCataractAddOn}
                  side = {props.side}
                  allergies = {props.allergies}
                  bat = {props.bat}
                  codeStatus = {props.codeStatus}
                  fName = {props.fName}
                  lName = {props.lName}
                  dob = {props.dob}

                  oopTotal={props.oopTotal}
                  oopPhysician={props.oopPhysician}
                  oopHospital={props.oopHospital}

                  lensCriFee={props.lensCriFee}
                  lensFlacsFee={props.lensFlacsFee}

                  physicianName={props.physicianName}
                  clinicName={props.clinicName}
                  clinicStreet={props.clinicStreet}
                  clinicCity={props.clinicCity}
                  clinicState={props.clinicState}
                  clinicZip={props.clinicZip}
                  clinicPhone={props.clinicPhone}
                  ptLensList={props.ptLensList}

                  linkTo='qr'
                  qrToken={props.qrToken}
                  cipherText={props.cipherText}
                  encryptionKey={props.encryptionKey}

                  bedsheetData={props.bedsheetData}
                  bedsheetId={props.bedsheetId}
                  bedsheetUse={props.bedsheetUse}
                  bedsheetName={props.bedsheetName}
                />

              {pageBreak}
              </>
            : null
          }


        {
          //get the lensConsentId array stored with the lensOption
          props.lensConsentId &&
          //map through the lensConsentId array
          props.lensConsentId.map(c =>
            //check the lensConsentId array against all the consentData
            props.consentData.map(m => (
              //Show consent if the consent from consentData has the same ID as the lensConsentId array
              (m.consent_id === c && 
                //and is a CRI consent and CRI is true, or its a FLACS consent and FLACS is true, or it's a financial disclosure and the patient owes out-of-pocket, 
                (
                  m.consent_type === "Lens consent"
                  ||
                  (
                    m.consent_type === "CRI consent"
                    &&
                    props.cri === true
                  )
                  ||
                  (
                    m.consent_type === "FLACS consent"
                    &&
                    props.flacs === true
                  )
                  ||
                  (
                    m.consent_type === "Financial disclosure"
                    &&
                    (
                      ( props.lensFlacsFee*1 > 0 && props.flacs === true )
                      ||
                      ( props.lensCriFee*1 > 0 && props.cri === true )
                      ||
                      props.lensPhysicianFee*1 > 0
                      ||
                      props.lensHospitalFee*1 > 0
                    )
                  )
                  //consider adding m.consent_type === "Other"
                ))
              ?
                <Fragment
                  key={c}
                  >
                  <ConsentRead
                    side={props.side}
                    lensModel = {props.lensModel}
                    lensOption = {props.lensOption}
                    lensTargetNear={props.lensTargetNear}
                    lensHospitalFee={props.lensHospitalFee}
                    lensPhysicianFee={props.lensPhysicianFee}
                    cri={props.cri}
                    lensCriFee={props.lensCriFee}
                    flacs={props.flacs}
                    lensFlacsFee={props.lensFlacsFee}
                    oopTotal={props.oopTotal}
                    oopPhysician={props.oopPhysician}
                    oopHospital={props.oopHospital}
                    physicianName={props.physicianName}
                    clinicName={props.clinicName}
                    clinicStreet={props.clinicStreet}
                    clinicCity={props.clinicCity}
                    clinicState={props.clinicState}
                    clinicZip={props.clinicZip}
                    clinicPhone={props.clinicPhone}
                    clinicFax={props.clinicFax}
                    consentData={m.consent_data}
                    consentId={m.consent_id}
                    />
                  {pageBreak}
                </Fragment> 
              : null
            ))
               
            )
        }

        <Vf8Questionnaire
          fName = {props.fName}
          lName = {props.lName}
          dob = {props.dob}
          lensModel = {props.lensModel}
          side = {props.side}
          q1={props.q1}
          q2={props.q2}
          q3={props.q3}
          q4={props.q4}
          q5={props.q5}
          q6={props.q6}
          q7={props.q7}
          q8={props.q8}
          q9={props.q9}
          q10={props.q10}
          q11={props.q11}
          q12={props.q12}
          q13={props.q13}
          q14={props.q14}
          />

        {pageBreak}



    <IhcConsent
      fName = {props.fName}
      lName = {props.lName}
      dob = {props.dob}
      side = {props.side}
      lensModel = {props.lensModel}
      physicianName = {props.physicianName}
      cri={props.cri}
      flacs={props.flacs}
      cataractAddOn={props.cataractAddOn}
      />


    {pageBreak}

    <PreopInstCataract
      fName = {props.fName}
      lName = {props.lName}
      dob = {props.dob}
      lensModel = {props.lensModel}
      side = {props.side}
      surgicalCenterPhone={props.surgicalCenterPhone}
      anesthesiaPhone={props.anesthesiaPhone}
      />

    {pageBreak}

    <PostopInstCataract
      fName = {props.fName}
      lName = {props.lName}
      dob = {props.dob}
      lensModel = {props.lensModel}
      side = {props.side}
      clinicPhone={props.clinicPhone}
      />

  
</div>

  }

    <div
      className="dontPrint"
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '30px 0 300px 0',
      }}>

    </div>
  </div>
  )
}






// //cataractpreop.com=============Change the following:  index.js G-tag to fp, "/" path in App.js to LandingPage, remove allergies from patient info =======================================

// return(
//   <div>
//     {props.cataractAddOn.some(c => c.value === 'false' && c.name === 'Cataract surgery')
//       ?
//         <>
//           <BackArrow
//             linkTo='/patientinfo'
//             />
//           <PrintPage/>

//         </>
    
//       : props.lensOption === 'Cataract vision'
//       ?
//       <div>
//         <BackArrow
//           linkTo='/patientinfo'
//           />
//         <p style={{
//             margin: '15px auto 0 auto',
//             color: 'red',
//             maxWidth: '800px',
//           }}>
//           REFRESHING THE SCREEN ERASES THE PATIENT INFORMATION YOU ENTERED PREVIOUSLY. PLEASE CLICK THE BACK ARROW TO RE-ENTER THE PATIENT'S INFORMATION.  DO NOT REFRESH YOUR BROWSER NEXT TIME.
//         </p>
//       </div>
//     :     
//       <div>
//         <BackArrow
//           linkTo='/nightsimulator'
//           />
//         <PrintPage/>

//         {
//           (props.bedsheetData && props.bedsheetData[0].data.length > 0)
//           ? 
//             <>
//               <BedsheetRead
//                 power={props.power}
//                 cylinder={props.cylinder}
//                 axis={props.axis}
//                 add={props.add}
//                 lensOption={props.lensOption}
//                 lensModel={props.lensModel}
//                 sulcusLens={props.sulcusLens}
//                 lensHospitalFee={props.lensHospitalFee}
//                 lensPhysicianFee={props.lensPhysicianFee}
//                 lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
//                 lensCorrectsNearVision={props.lensCorrectsNearVision}
//                 flacs={props.flacs}
//                 cri={props.cri}
//                 lensTargetNear={props.lensTargetNear}
//                 biometer={props.biometer}
//                 biometerAstig={props.biometerAstig}
//                 biometerAxis={props.biometerAxis}
//                 chordMuX={props.chordMuX}
//                 chordMuY={props.chordMuY}
//                 chordMuTotal={props.chordMuTotal}
//                 topographer={props.topographer}
//                 topographerAstig={props.topographerAstig}
//                 topographerAxis={props.topographerAxis}
//                 topographerHoa={props.topographerHoa}
//                 topographerSphAb={props.topographerSphAb}
//                 equipmentPreference={props.equipmentPreference}
//                 lensKAstigMin={props.lensKAstigMin}
//                 bcva={props.bcva}
//                 ns={props.ns}
//                 contraindications={props.contraindications}
//                 cataractAddOn={props.cataractAddOn}
//                 setCataractAddOn={props.setCataractAddOn}
//                 side = {props.side}
//                 allergies = {props.allergies}
//                 bat = {props.bat}
//                 codeStatus = {props.codeStatus}
//                 fName = {props.fName}
//                 lName = {props.lName}
//                 dob = {props.dob}

//                 oopTotal={props.oopTotal}
//                 oopPhysician={props.oopPhysician}
//                 oopHospital={props.oopHospital}

//                 lensCriFee={props.lensCriFee}
//                 lensFlacsFee={props.lensFlacsFee}

//                 physicianName={props.physicianName}
//                 clinicName={props.clinicName}
//                 clinicStreet={props.clinicStreet}
//                 clinicCity={props.clinicCity}
//                 clinicState={props.clinicState}
//                 clinicZip={props.clinicZip}
//                 clinicPhone={props.clinicPhone}
//                 ptLensList={props.ptLensList}

//                 linkTo='qr'
//                 qrToken={props.qrToken}
//                 cipherText={props.cipherText}
//                 encryptionKey={props.encryptionKey}

//                 bedsheetData={props.bedsheetData}
//                 bedsheetId={props.bedsheetId}
//                 bedsheetUse={props.bedsheetUse}
//                 bedsheetName={props.bedsheetName}
//               />

//             {pageBreak}
//             </>
//           : null
//         }

//         {
//           //get the lensConsentId array stored with the lensOption
//           props.lensConsentId &&
//           //map through the lensConsentId array
//           props.lensConsentId.map(c =>
//             //check the lensConsentId array against all the consentData
//             props.consentData.map(m => (
//               //Show consent if the consent from consentData has the same ID as the lensConsentId array
//               (m.consent_id === c && 
//                 //and is a CRI consent and CRI is true, or its a FLACS consent and FLACS is true, or it's a financial disclosure and the patient owes out-of-pocket, 
//                 (
//                   m.consent_type === "Lens consent"
//                   ||
//                   (
//                     m.consent_type === "CRI consent"
//                     &&
//                     props.cri === true
//                   )
//                   ||
//                   (
//                     m.consent_type === "FLACS consent"
//                     &&
//                     props.flacs === true
//                   )
//                   ||
//                   (
//                     m.consent_type === "Financial disclosure"
//                     &&
//                     (
//                       ( props.lensFlacsFee*1 > 0 && props.flacs === true )
//                       ||
//                       ( props.lensCriFee*1 > 0 && props.cri === true )
//                       ||
//                       props.lensPhysicianFee*1 > 0
//                       ||
//                       props.lensHospitalFee*1 > 0
//                     )
//                   )
//                   //consider adding m.consent_type === "Other"
//                 ))
//               ?
//                 <Fragment
//                   key={c}
//                   >
//                   <ConsentRead
//                     side={props.side}
//                     lensModel = {props.lensModel}
//                     lensOption = {props.lensOption}
//                     lensTargetNear={props.lensTargetNear}
//                     lensHospitalFee={props.lensHospitalFee}
//                     lensPhysicianFee={props.lensPhysicianFee}
//                     cri={props.cri}
//                     lensCriFee={props.lensCriFee}
//                     flacs={props.flacs}
//                     lensFlacsFee={props.lensFlacsFee}
//                     oopTotal={props.oopTotal}
//                     oopPhysician={props.oopPhysician}
//                     oopHospital={props.oopHospital}
//                     physicianName={props.physicianName}
//                     clinicName={props.clinicName}
//                     clinicStreet={props.clinicStreet}
//                     clinicCity={props.clinicCity}
//                     clinicState={props.clinicState}
//                     clinicZip={props.clinicZip}
//                     clinicPhone={props.clinicPhone}
//                     clinicFax={props.clinicFax}
//                     consentData={m.consent_data}
//                     consentId={m.consent_id}
//                     />
//                   {pageBreak}
//                 </Fragment> 
//               : null
//             ))
               
//             )
//         }

//         <Vf8Questionnaire
//           fName = {props.fName}
//           lName = {props.lName}
//           dob = {props.dob}
//           lensModel = {props.lensModel}
//           side = {props.side}
//           q1={props.q1}
//           q2={props.q2}
//           q3={props.q3}
//           q4={props.q4}
//           q5={props.q5}
//           q6={props.q6}
//           q7={props.q7}
//           q8={props.q8}
//           q9={props.q9}
//           q10={props.q10}
//           q11={props.q11}
//           q12={props.q12}
//           q13={props.q13}
//           q14={props.q14}
//           />

  

//     </div>
//   }

//     <div
//       className="dontPrint"
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         margin: '30px 0 300px 0',
//       }}>

//     </div>
//   </div>
//   )
// }
