import React, { useState } from 'react';

import {useNavigate} from 'react-router-dom';

import Header from '../partials/Header'
import Navbar from '../partials/Navbar'
import ForwardArrowValidation from '../partials/ForwardArrowValidation'
import ContraindicationsPtInfo from '../contraindications/ContraindicationsPtInfo'
import IOLMaster700 from './IOLMaster700'
import IOLMaster500 from './IOLMaster500'
import LenstarLS900 from './LenstarLS900'
import Argos from './Argos'
import Atlas9000 from './Atlas9000'
import Pentacam from './Pentacam'
import CustomSelectThree from './CustomSelectThree'
import CataractAddOns from './CataractAddOns'

import { BsEye } from "react-icons/bs";
import { GiMedicines } from "react-icons/gi";
import { IoGlassesOutline } from "react-icons/io5"
import { TbEyeTable } from "react-icons/tb";



export default function PatientInfo ( props ) {


  let p = (props.power && props.power.toLowerCase() === 'plano' ||
    props.power.toLowerCase() === 'pl' ||
    props.power === '')
      ? 0
      : props.power*1;

  let iolPentacamAstig = Math.abs(props.biometerAstig*1 - props.topographerAstig*1)


  let iolPentacamAxis;
  // compensate for fact that 180 = 0
  props.biometerAxis*1 >= 165 && props.topographerAxis*1 <= 15
    ? iolPentacamAxis = ((props.topographerAxis*1 + 180)-props.biometerAxis*1)
    : props.biometerAxis*1 <= 15 && props.topographerAxis*1 >= 165
      ? iolPentacamAxis = ((props.biometerAxis*1 + 180) - props.topographerAxis*1)
      : iolPentacamAxis = Math.abs(props.biometerAxis*1 - props.topographerAxis*1)


  // console.log(iolPentacamAstig)
  // console.log(iolPentacamAxis)

  //++++++++++++++ERROR MESSAGE HANDLING++++++++++++++++++++++++++++++++++++++++++++
  //create a hook so that you can store the messages in state so they show up in JSX tags
  const[errorMessage, setErrorMessage]=useState([])

  //create blank array to send error messages
  let errorMessageArr = [];

  //create array of objects with error messages you want.  NB: Can also run code if you want to make the name blank using a test of some sort that returns a blank if the test is false

  // let chordMuError =
  //   (props.biometer === "IOL Master 700")
  //     ? props.chordMuTotal
  //     : props.chordMuX === "" || props.chordMuY === ""
  //       ? ''
  //       : props.chordMuTotal

  let axisError =
    (
      props.axis === ''
      &&
      (
        props.cylinder.toLowerCase() === "sphere"
        ||
        props.cylinder.toLowerCase() === "sph"
      )
    )
      ? 'noerror'
      : props.axis

  let propsArr = [
    // {name: `${props.codeStatus}`, message: '*Please choose code status'},
    // {name: `${props.allergies}`, message: '*Please enter patient allergies or indicate NKDA'},
    {name: `${props.side}`, message: '*Please choose operative eye'},
    {name: `${props.power}`, message: '*Please enter MRx power'},
    {name: `${props.cylinder}`, message: '*Please enter MRx cylinder'},
    {name: `${axisError}`, message: '*Please enter MRx axis (If no cylinder, please enter "Sphere")'},
    {name: `${props.add}`, message: '*Please enter MRx add power'},
    {name: `${props.bcva}`, message: '*Please enter BCVA (best corrected visual acuity)'},
    // {name: `${props.ns}`, message: '*Please enter cataract grade'},
    // {name: `${props.bat}`, message: '*Please enter brightness acuity testing (BAT)'},
    {name: `${props.biometerAstig}`, message: '*Please enter biometer astigmatism'},
    {name: `${props.biometerAxis}`, message: '*Please enter biometer steep axis'},
    // {name: `${chordMuError}`, message: '*Please enter biometer chord mu value(s)'},
    {name: `${props.topographerAstig}`, message: '*Please enter topographer astigmatism'},
    {name: `${props.topographerAxis}`, message: '*Please enter topographer steep axis'},
    {name: `${props.topographerSphAb}`, message: '*Please enter topographer spherical aberration ("Sph. Ab.")'},
    {name: `${props.topographerHoa}`, message: '*Please enter topographer higher order aberration'},
  ];

  //if not doing cataract surgery, just need to check for laterality for now
  let noCatArr = [
    {name: `${props.side}`, message: '*Please choose operative eye'},
  ];
  // console.log(propsArr)


  const navigate = useNavigate();

  //Map through props you are interested in and send error message to errorMessageArr if there is an error
  const handleFormValidate = () => {
    const errorMessageArr = [];
  
    if(props.cataractAddOn.some(c => c.value === 'true' && c.name === 'Cataract surgery')){
      Object.entries(propsArr).forEach(p => {
        if (p[1].name === '') {
          errorMessageArr.push(p[1].message);
        }
      });
    } else {
      Object.entries(noCatArr).forEach(p => {
        if (p[1].name === '') {
          errorMessageArr.push(p[1].message);
        }
      });
    }
    
  
    setErrorMessage(errorMessageArr);
  
    if (errorMessageArr.length > 0) {
      const timer = setTimeout(() => {
        setErrorMessage([]);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      // Check if any cataractAddOn has value === 'true' and name === 'Cataract surgery'
      const addOn = props.cataractAddOn.some(c => c.value === 'true' && c.name === 'Cataract surgery');
  
      props.setUserType('md');
      navigate(addOn ? '/questionnaire' : '/paperwork');
    }
  };
  

let addOnLabel = (props.cataractAddOn).filter(c => (
    (c.value === 'true')
  ))
  
  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


  let inputStyle = {
    border: 'none',
    padding: '3px 10px',
    margin: '2px',
    fontSize: '18px',
    width: '135px',
    height: '40px',
    borderRadius: '5px',
    background: '#F2F3F4',
    textAlign:'center',
  }


  let pWarningStyle = {
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  let warnStyle ={
    fontSize: '17px',
    margin: '3px 0 0 0',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: 'red',
  }

  let subtitleStyle = {
    textAlign: 'left',
    padding: '0 0 0 0px',
    fontSize: '18px',
    fontWeight: '600',
    margin: 'auto 0',
  }

  let iconStyle = {
    margin: 'auto 0',
    padding: '0',
    width: '60px',
    display: 'flex',
    justifyContent: 'center',
  }
  // window.addEventListener("resize", props.onScreenChange)


  return(
    <div style={{width: '100vw'}}>
      <Navbar/>
      <ForwardArrowValidation
        linkTo='/questionnaire'
        onFormValidate={handleFormValidate}
        setUserType={props.setUserType}
        onClick={(props.setAcknowledgePremium(false))}
        />
      <Header
        title={"Patient Information"}
        subtitle={"Please enter patient information below"}
        />
      <div
        style={{
          display: 'flex',
          flexDirection:'column',
          justifyContent: 'start',
          margin: '40px 0 0 0'
        }}>

          <div>
          {/*   <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <p style={{
                  width: '200px',
                  height: 'auto',
                  textAlign: 'left',
                  padding: '0 5px 0 0',
                  margin: '15px 0 0 0',
                  fontSize: '18px',
                  fontWeight: '600',
                }}>Code Status: </p>
                <select
                  style={{
                    width: '100px',
                    height: '40px',
                    fontSize: '18px',
                    borderRadius: '10px',
                    padding: '0 0 0 10px',
                    outline: 'none',
                    textDecoration: 'none',
                  }}
                  value={props.codeStatus}
                  onChange={e => props.setCodeStatus(e.target.value)}
                  >
                    <option value=''></option>
                    <option value='FULL'>FULL</option>
                    <option value='DNR'>DNR</option>
                </select>

            </div> */}


            <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <div 
                style={{
                  display: 'flex',
                  width: "210px"
                }}
              >
                <div
                  style={iconStyle}
                >
                  <BsEye
                    style={{
                      fontSize: '40px',
                    }}
                  />
                </div>
                
                <p style={subtitleStyle}>Operative Eye </p>
              </div>
                <select
                  style={{
                    width: '100px',
                    height: '40px',
                    fontSize: '18px',
                    padding: '0 0 0 10px',
                    borderRadius: '10px',
                    outline: 'none',
                  }}
                  value={props.side}
                  onChange={e => props.setSide(e.target.value)}
                  name="side"
                  >
                    <option value=''></option>
                    <option value='RIGHT'>RIGHT</option>
                    <option value='LEFT'>LEFT</option>
                </select>

            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <div 
                style={{
                  display: 'flex',
                  width: "210px"
                }}
              >
                <div
                  style={iconStyle}
                >
                  <GiMedicines
                    style={{
                      fontSize: '40px',
                    }}
                  />
                </div>
                
                <p style={subtitleStyle}>Allergies </p>
              </div>
              <input
                style={{
                  border: 'none',
                  padding: '3px 10px',
                  margin: '2px',
                  fontSize: '18px',
                  width: '580px',
                  height: '40px',
                  borderRadius: '5px',
                  background: '#F2F3F4',
                  textAlign:'left',
                }}
                value={props.allergies}
                onChange={e => props.setAllergies(e.target.value)}
                placeholder="Allergies"
              />
            </div>


            <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <div 
                style={{
                  display: 'flex',
                  width: "210px"
                }}
              >
                <div
                  style={iconStyle}
                >
                  <IoGlassesOutline
                    style={{fontSize: '50px'}}
                  />
                </div>
                
                <p style={subtitleStyle}>MRx </p>
              </div>

              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: ['Plano']},
                  {rowPlace: 'left', data: minusPowers},
                  {rowPlace: 'right', data: plusPowers},
                ]}
                hook={props.power}
                setHook={props.setPower}
                hookName='power'
                selectWidth='146'
              />

              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: cylPowers},
                ]}
                hook={props.cylinder}
                setHook={props.setCylinder}
                hookName='cylinder'
                selectWidth='146'
              />

              {
                props.cylinder === 'Sphere'
                ? 
                  <div
                    style={{
                      border: 'none',
                      padding: '3px 10px',
                      margin: '2px',
                      fontSize: '18px',
                      width: '135px',
                      height: '40px',
                      borderRadius: '5px',
                      background: '#FFF',
                      textAlign:'center',
                    }}
                  >
                  </div>
                : 
                  <input
                    style={inputStyle}
                    value={props.axis}
                    onChange={e => props.setAxis(e.target.value)}
                    placeholder="axis"
                    type='number'
                    onWheel={(e) => e.target.blur()}
                  />
              }

              <CustomSelectThree 
                info = {[
                  {rowPlace: 'top', data: addPowers},
                ]}
                hook={props.add}
                setHook={props.setAdd}
                hookName='add'
                selectWidth='146'
              />
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <div 
                style={{
                  display: 'flex',
                  width: "210px"
                }}
              >
                <div
                  style={iconStyle}
                >
                  <TbEyeTable
                    style={{fontSize: '40px'}}
                  />
                </div>
                
                <p style={subtitleStyle}>BCVA </p>
              </div>

              <div style={{
                  display: 'flex'
                }}>
                <p style={{fontSize: '18px', margin: '15px 0 0 0',}}>20/</p>
                <CustomSelectThree 
                  info = {[
                    {rowPlace: 'top', data: visionPowers},
                  ]}
                  hook={props.bcva}
                  setHook={props.setBcva}
                  hookName='xx'
                  selectWidth='50'
                />
              </div>
            </div>

            {/* <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <p style={{
                  width: '200px',
                  height: '36px',
                  textAlign: 'left',
                  padding: '0 5px 0 0',
                  margin: '15px 0 0 0',
                  fontSize: '18px',
                  fontWeight: '600',
                }}>Cataract grade: </p>

              <div style={{
                  display: 'flex'
                }}>
                <CustomSelectThree 
                  info = {[
                    {rowPlace: 'top', data: cataractGrades},
                  ]}
                  hook={props.ns}
                  setHook={props.setNs}
                  hookName='x'
                  selectWidth='50'
                />
              </div>
            </div> */}

            {/* <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 8px 80px',
              }}>
              <p style={{
                  width: '200px',
                  height: '36px',
                  textAlign: 'left',
                  padding: '0 5px 0 0',
                  margin: '15px 0 0 0',
                  fontSize: '18px',
                  fontWeight: '600',
                }}>BAT: </p>

              <div style={{
                  display: 'flex'
                }}>
                <p style={{fontSize: '18px', margin: '15px 0 0 0',}}>20/</p>
                <CustomSelectThree 
                  info = {[
                    {rowPlace: 'top', data: visionPowers},
                  ]}
                  hook={props.bat}
                  setHook={props.setBat}
                  hookName='xx'
                  selectWidth='50'
                />
              </div>
            </div> */}

            {(props.biometer === 'IOL Master 700')
              ?
              <IOLMaster700
                biometer={props.biometer}
                biometerAstig={props.biometerAstig}
                setBiometerAstig={props.setBiometerAstig}
                biometerAxis={props.biometerAxis}
                setBiometerAxis={props.setBiometerAxis}
                chordMuTotal={props.chordMuTotal}
                setChordMuTotal={props.setChordMuTotal}
                />
              : (props.biometer === 'IOL Master 500')
                ?
                <IOLMaster500
                  biometer={props.biometer}
                  biometerAstig={props.biometerAstig}
                  setBiometerAstig={props.setBiometerAstig}
                  biometerAxis={props.biometerAxis}
                  setBiometerAxis={props.setBiometerAxis}
                  chordMuX={props.chordMuX}
                  setChordMuX={props.setChordMuX}
                  chordMuY={props.chordMuY}
                  setChordMuY={props.setChordMuY}
                  chordMuTotal={props.chordMuTotal}
                  setChordMuTotal={props.setChordMuTotal}
                  />
                : (props.biometer === 'Lenstar LS 900')
                  ?
                    <LenstarLS900
                      biometer={props.biometer}
                      biometerAstig={props.biometerAstig}
                      setBiometerAstig={props.setBiometerAstig}
                      biometerAxis={props.biometerAxis}
                      setBiometerAxis={props.setBiometerAxis}
                      chordMuX={props.chordMuX}
                      setChordMuX={props.setChordMuX}
                      chordMuY={props.chordMuY}
                      setChordMuY={props.setChordMuY}
                      chordMuTotal={props.chordMuTotal}
                      setChordMuTotal={props.setChordMuTotal}
                      />
                  : (props.biometer === 'Argos')
                    ?
                      <Argos
                        biometer={props.biometer}
                        biometerAstig={props.biometerAstig}
                        setBiometerAstig={props.setBiometerAstig}
                        biometerAxis={props.biometerAxis}
                        setBiometerAxis={props.setBiometerAxis}
                        chordMuX={props.chordMuX}
                        setChordMuX={props.setChordMuX}
                        chordMuY={props.chordMuY}
                        setChordMuY={props.setChordMuY}
                        chordMuTotal={props.chordMuTotal}
                        setChordMuTotal={props.setChordMuTotal}
                        />
                      : <div>Please login or go to "My Account" > "Topgrapher and Biometer" to choose a biometer</div>
            }

            {(props.topographer === 'Atlas')
              ?
                <Atlas9000
                  topographer={props.topographer}
                  topographerAstig={props.topographerAstig}
                  setTopographerAstig={props.setTopographerAstig}
                  topographerAxis={props.topographerAxis}
                  setTopographerAxis={props.setTopographerAxis}
                  topographerSphAb={props.topographerSphAb}
                  setTopographerSphAb={props.setTopographerSphAb}
                  topographerHoa={props.topographerHoa}
                  setTopographerHoa={props.setTopographerHoa}
                  comaH={props.comaH}
                  setComaH={props.setComaH}
                  comaV={props.comaV}
                  setComaV={props.setComaV}
                  />
                : (props.topographer === 'Pentacam')
                  ?
                    <Pentacam
                      topographer={props.topographer}
                      topographerAstig={props.topographerAstig}
                      setTopographerAstig={props.setTopographerAstig}
                      topographerAxis={props.topographerAxis}
                      setTopographerAxis={props.setTopographerAxis}
                      topographerSphAb={props.topographerSphAb}
                      setTopographerSphAb={props.setTopographerSphAb}
                      topographerHoa={props.topographerHoa}
                      setTopographerHoa={props.setTopographerHoa}
                      />
                    : <div>Please login or go to "My Account" > "Topgrapher and Biometer" to choose a topographer</div>
            }

            <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 50px 80px',
              }}>
              <div style={{width: '205px'}}>
                <p style={{
                    width: '200px',
                    height: 'auto',
                    textAlign: 'left',
                    padding: '0 5px 0 0',
                    margin: '15px 0 0 0',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}>Eye Conditions:
                </p>
                <p style={{
                    width: '200px',
                    height: 'auto',
                    textAlign: 'left',
                    padding: '0 5px 0 0',
                    margin: '5px 0 0 0',
                    fontSize: '16px',
                    fontWeight: '500',
                    color: 'red',
                  }}>Please verify each one
                </p>
              </div>
              <ContraindicationsPtInfo
                contraindications={props.contraindications}
                setContraindications={props.setContraindications}
                />
            </div>
            {
              (errorMessage && errorMessage.length > 0)
              ? 
                <div
                  style={{
                    justifyContent: 'left',
                    margin: '0 0 50px 280px',
                    padding: '20px',
                    border: '1px solid #292929',
                    borderRadius: '20px',
                    zIndex: '10',
                    position: 'fixed',
                    top: '60px',
                    left: '0',
                    background: '#FFF',
                  }}>
                  {errorMessage.map((m, i) => (
                      <p
                        key={i}
                        style={pWarningStyle}
                        >
                        {m}
                      </p>
                    ))
                  }
                </div> 
              : null
            }
            <div style={{
                display: 'flex',
                justifyContent: 'left',
                margin: '0 0 50px 80px',
              }}>
              <div style={{width: '205px'}}>
                <p style={{
                    width: '200px',
                    height: 'auto',
                    textAlign: 'left',
                    padding: '0 5px 0 0',
                    margin: '15px 0 0 0',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}>Additional procedures:
                </p>
              </div>
              <CataractAddOns
                className={
                  addOnLabel.length > 0
                    ? null
                    : 'dontPrint'
                }
                label='CORNEA PROCEDURES'
                hook={props.cataractAddOn}
                setHook={props.setCataractAddOn}
                side={props.side}
              />
            </div>
            <hr style={{width: '600px'}}/>
          </div>
      </div>
    </div>

  )
}


const minusPowers = [
  '-0.25',
  '-0.50',
  '-0.75',
  '-1.00',
  '-1.25',
  '-1.50',
  '-1.75',
  '-2.00',
  '-2.25',
  '-2.50',
  '-2.75',
  '-3.00',
  '-3.25',
  '-3.50',
  '-3.75',
  '-4.00',
  '-4.25',
  '-4.50',
  '-4.75',
  '-5.00',
  '-5.25',
  '-5.50',
  '-5.75',
  '-6.00',
  '-6.25',
  '-6.50',
  '-6.75',
  '-7.00',
  '-7.25',
  '-7.50',
  '-7.75',
  '-8.00',
  '-8.25',
  '-8.50',
  '-8.75',
  '-9.00',
  '-9.25',
  '-9.50',
  '-9.75',
  '-10.00',
  '-10.25',
  '-10.50',
  '-10.75',
  '-11.00',
  '-11.25',
  '-11.50',
  '-11.75',
  '-12.00',
  '-12.25',
  '-12.50',
  '-12.75',
  '-13.00',
  '-13.25',
  '-13.50',
  '-13.75',
  '-14.00',
  '-14.25',
  '-14.50',
  '-14.75',
  '-15.00',
  '-15.25',
  '-15.50',
  '-15.75',
  '-16.00',
  '-16.25',
  '-16.50',
  '-16.75',
  '-17.00',
  '-17.25',
  '-17.50',
  '-17.75',
  '-18.00',
  '-18.25',
  '-18.50',
  '-18.75',
  '-19.00',
  '-19.25',
  '-19.50',
  '-19.75',
  '-20.00',
  '-20.25',
  '-20.50',
  '-20.75',
  '-21.00',
  '-21.25',
  '-21.50',
  '-21.75',
  '-22.00',
  '-22.25',
  '-22.50',
  '-22.75',
  '-23.00',
  '-23.25',
  '-23.50',
  '-23.75',
  '-24.00',
  '-24.25',
  '-24.50',
  '-24.75',
  '-25.00',
  '-25.25',
  '-25.50',
  '-25.75',
  '-26.00',
  '-26.25',
  '-26.50',
  '-26.75',
  '-27.00',
  '-27.25',
  '-27.50',
  '-27.75',
  '-28.00',
  '-28.25',
  '-28.50',
  '-28.75',
  '-29.00',
  '-29.25',
  '-29.50',
  '-29.75',
  '-30.00',
]

const plusPowers = [
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
  '+4.25',
  '+4.50',
  '+4.75',
  '+5.00',
  '+5.25',
  '+5.50',
  '+5.75',
  '+6.00',
  '+6.25',
  '+6.50',
  '+6.75',
  '+7.00',
  '+7.25',
  '+7.50',
  '+7.75',
  '+8.00',
  '+8.25',
  '+8.50',
  '+8.75',
  '+9.00',
  '+9.25',
  '+9.50',
  '+9.75',
  '+10.00',
]

const cylPowers = [
  'Sphere',
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
  '+3.75',
  '+4.00',
  '+4.25',
  '+4.50',
  '+4.75',
  '+5.00',
  '+5.25',
  '+5.50',
  '+5.75',
  '+6.00',
  '+6.25',
  '+6.50',
  '+6.75',
  '+7.00',
  '+7.25',
  '+7.50',
  '+7.75',
  '+8.00',
  '+8.25',
  '+8.50',
  '+8.75',
  '+9.00',
  '+9.25',
  '+9.50',
  '+9.75',
  '+10.00',
]

const addPowers = [
  'No add',
  '+0.25',
  '+0.50',
  '+0.75',
  '+1.00',
  '+1.25',
  '+1.50',
  '+1.75',
  '+2.00',
  '+2.25',
  '+2.50',
  '+2.75',
  '+3.00',
  '+3.25',
  '+3.50',
]

const visionPowers = [
  '20',
  '25',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '100',
  '150',
  '200',
  '400',
  'CF',
  'HM'
]

const cataractGrades = [
  '1',
  '2',
  '3',
  '4',
]

// <div
//   onClick={(e) => props.setStep('2')}
//   style={{
//     borderRadius: '5px',
//     width: '100px',
//     margin: '30px auto 300px auto',
//     padding: '7px',
//     background: '#007bff',
//     color: '#fff',
//     fontSize: '20px',
//     textAlign: 'center',
//     cursor: 'pointer',
//   }}
//   >
//   Next
// </div>

// let nextButton = (e) => {
  // (cylinder.toLowerCase() === 'sphere' ||
  // cylinder.toLowerCase() === 'sph'
  //   ? 'sphere'
  //   : cylinder
  // side === '' ||
  // bat === '' ||
  // allergies === '' ||
  // props.codeStatus === '' ||
  // props.biometerAxis === '' ||
  // props.biometerAstig === '' ||
  // props.topographerAstig === '' ||
  // props.topographerAxis === '' ||
  // topographerSphAb === '' ||
  // topographerHoa === '' ||
  // props.power === '' ||
  // (cylinder !== '' && sphereCheck !== 'sphere' && axis === ''))
  // ? alert("Please check all required inputs for completeness and spelling")
  // :
//   window.location = '/visionsimulator';
// }

  // const [isPrinting, setIsPrinting] = useState(false);
  //
  // const printItems = () => {
  //   setIsPrinting(true);
  //   window.print();
  // };
